
import { defineComponent } from 'vue';
import GoodGirlKarma from '@/components/GoodGirlKarma.vue';

export default defineComponent({
    name: 'GoodGirlKarmaView',
    components: {
        GoodGirlKarma
    }
});
