
import { defineComponent } from 'vue';
import BuyButton from './BuyButton.vue';
import { VueAgile } from 'vue-agile';
import Box from '@/components/Box.vue';

export default defineComponent({
    name: 'GoodGirlKarma',
    components: {
        VueAgile,
        BuyButton,
        Box
    },
    props: {},
    data() {
        return {
            carousel: ['page 1-min.jpg', 'page 4-min.jpg', 'Page 6-min.jpg', 'Page 12-min.jpm'],
            carouselVisibility: 'invisible',
            coverVisibility: '',
            agileOptions: { navButtons: false }
        };
    },
    mounted() {
        const self = this;
        setTimeout(() => {
            self.carouselVisibility = '';
            self.coverVisibility = 'hidden';
        }, 0);
    },
    methods: {
        getImgUrl(pic: string) {
            // eslint-disable-next=line
            // eslint-disable-next-line global-require
            return require(`@assets/GoodGirlKarma/${pic}`);
        }
    }
});
